/*----  Main Style  ----*/
#cards_landscape_wrap-2{
  text-align: center;
  background: #F7F7F7;
}
#cards_landscape_wrap-2 .container{
  padding-top: 80px; 
  padding-bottom: 100px;
}
#cards_landscape_wrap-2 a{
  text-decoration: none;
  outline: none;
}
#cards_landscape_wrap-2 .card-flyer {
  border-radius: 5px;
}
#cards_landscape_wrap-2 .card-flyer .image-box{
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.50);
  border-radius: 5px;
}
#cards_landscape_wrap-2 .card-flyer .image-box img{
  /* -webkit-transition:all .9s ease; 
  -moz-transition:all .9s ease; 
  -o-transition:all .9s ease;
  -ms-transition:all .9s ease;  */
  width: 20;
  height: auto;
}
#cards_landscape_wrap-2 .card-flyer:hover .image-box img{
  opacity: 0.7;
  -webkit-transform:scale(1.15);
  -moz-transform:scale(1.15);
  -ms-transform:scale(1.15);
  -o-transform:scale(1.15);
  transform:scale(1.15);
}
#cards_landscape_wrap-2 .card-flyer .text-box{
  text-align: center;
}
#cards_landscape_wrap-2 .card-flyer .text-box .text-container{
  padding: 30px 18px;
}
#cards_landscape_wrap-2 .card-flyer{
  background: #FFFFFF;
  margin-top: 50px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.40);
}
#cards_landscape_wrap-2 .card-flyer:hover{
  background: #fff;
  box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.50);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  margin-top: 50px;
}
#cards_landscape_wrap-2 .card-flyer .text-box p{
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px; 
  font-size: 14px;
  letter-spacing: 1px;
  color: #000000;
}
#cards_landscape_wrap-2 .card-flyer .text-box h6{
  margin-top: 0px;
  margin-bottom: 4px; 
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Roboto Black', sans-serif;
  letter-spacing: 1px;
  color: #00acc1;
}


.wrapper {
  width: 100%;
  height: 100vh;
  color: #fae881;
  overflow:hidden;
}

.flex_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box {
  position: absolute;
  background-color: black;
  width: 50px;
  height: 50px;
  transition: 0.2s;
}

.form {
  position: absolute;
  padding: 30px 20px;
  /* width: 320px; */
  border-radius: 7px;
  background-color: white;
  backdrop-filter: blur(5px);
  background-color: rgba(158, 189, 199, 0.288);
  overflow: hidden;
}

.input {
  display: flex;
  flex-direction: column;
}

input, textarea, select {
  padding: 8px 10px;
  margin: 3px 8px 16px 8px;
  background-color: rgba(222, 239, 248, 0.877);
  border: 0px transparent;
  border-radius: 5px;
  color:rgb(97, 4, 184);
  font-size: 16px;
  word-break: break-all; /* haven't found the best solution here yet, paragraphs are difficult to deal with */
  hyphens: auto;
  z-index: 1;
}

label {
  margin: 0 8px;
  font-size: 14px;
  color: white;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
}

button {
  padding: 8px 14px;
  margin: 20px 8px 0 0;
  border-width: 0px;
  border-radius: 5px;
  color: white;
  background-color: #b69269;
  transition: 0.3s;
  z-index: 1;
}

button:hover {
  color: rgb(60, 35, 153);
  background-color: white;
}

.star {
  position: absolute;
  transition: 1s;
  overflow: hidden;
  opacity: 1;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  z-index: -90;
}

.star svg {
  height: 100%;
  width: auto;
}

.star_inner {
  height: 15px;
}

.cover {
  position: fixed;
  top: 0px;
  left: 0px;
  pointer-events: none;
  height: 100vh;
  width: 100%;
  z-index: 999;
  overflow: hidden;
}

.ghost {
  position: absolute;
  padding: 8px 10px;
  border: 0px transparent;
  border-radius: 5px;
  z-index: 1;
  color:rgb(97, 4, 184);
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
}

.ghost > div {
  margin: 0;
  padding: 0;
  transition: 5s;
}

.blank {
  width: 4px;
}

form.fade {
  animation: fade forwards ease 5s;
}

@keyframes fade {
  0% {
    opacity: 1;
    transform: translate(0vw, 0) skew(0);
  }
  20% {
    opacity: 0.8;
    transform: translate(0vw, 0) skew(0);
  }

  100% {
    opacity: 0;
    transform: translate(50vw, -50vh) skew(-45deg);
  }
}

form.fade_in {
  animation: fade_in forwards ease 6s;
}

@keyframes fade_in {
  0% {
    opacity: 0;
    transform: translate(-50vw, 150vh) skew(-45deg);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0) skew(0);
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(140, 145, 216);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(140, 145, 216);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(140, 145, 216);
}

.sign {
  position: absolute;
  color: white;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}





#toast {
  visibility: hidden;
  max-width: 350px;
  height: 50px;
  /*margin-left: -125px;*/
  margin: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;

  position: fixed;
  z-index: 1;
  left: 0;right:0;
  bottom: 30px;
  font-size: 17px;
  word-break: break-all;
   white-space: break-spaces;
}

#toast #desc{

  
  color: #fff;
 
  padding: 16px;
  
  overflow: hidden;
white-space: nowrap;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, expand 0.5s 0.5s,stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes expand {
  from {min-width: 50px} 
  to {min-width: 350px}
}

@keyframes expand {
  from {min-width: 50px}
  to {min-width: 350px}
}
@-webkit-keyframes stay {
  from {min-width: 350px} 
  to {min-width: 350px}
}

@keyframes stay {
  from {min-width: 350px}
  to {min-width: 350px}
}
@-webkit-keyframes shrink {
  from {min-width: 350px;} 
  to {min-width: 50px;}
}

@keyframes shrink {
  from {min-width: 350px;} 
  to {min-width: 50px;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 60px; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 60px; opacity: 0;}
}



.popup {
  position: fixed;
  bottom: -60px;
  right: 10px;
  background: #33a6e8;
  padding: 15px 40px;
  font-size: 15px;
  font-family: sans-serif;
  color: #ffffff;
  border-radius: 10px;
  animation: popup 5s ease-in-out;
}

@keyframes popup {
  10%, 90% {
    bottom: 10px;
  }
  100% {
    bottom: -60px;
  }
}



.leaderboard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 308px;
  background: linear-gradient(to bottom, #3a404d, #181c26);
  border-radius: 10px;
  box-shadow: 0 7px 30px rgba(62, 9, 11, .3);
  overflow: scroll;
}
.leaderboard h1 {
  font-size: 18px;
  color: #e1e1e1;
  padding: 12px 13px 18px;
}
.leaderboard h1 svg {
  width: 25px;
  height: 26px;
  position: relative;
  top: 3px;
  margin-right: 6px;
  vertical-align: baseline;
}
.leaderboard ol {
  counter-reset: leaderboard;
  width: 90%;
  margin-left: 1em;
}
.leaderboard ol li {
  position: relative;
  z-index: 1;
  font-size: 14px;
  counter-increment: leaderboard;
  padding: 18px 10px 18px 50px;
  cursor: pointer;
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
}
.leaderboard ol li::before {
  content: counter(leaderboard);
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #c24448;
  background: #fff;
  border-radius: 20px;
  text-align: center;
}
.leaderboard ol li mark {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 18px 10px 18px 50px;
  margin: 0;
  background: none;
  color: #fff;
}
.leaderboard ol li mark::before, .leaderboard ol li mark::after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: -11px;
  left: -9px;
  border-top: 10px solid #c24448;
  border-left: 10px solid transparent;
  transition: all 0.1s ease-in-out;
  opacity: 0;
}
.leaderboard ol li mark::after {
  left: auto;
  right: -9px;
  border-left: none;
  border-right: 10px solid transparent;
}
.leaderboard ol li small {
  position: relative;
  z-index: 2;
  display: block;
  text-align: right;
}
.leaderboard ol li::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fa6855;
  box-shadow: 0 3px 0 rgba(0, 0, 0, .08);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.leaderboard ol li:nth-child(1) {
  background: #fa6855;
}
.leaderboard ol li:nth-child(1)::after {
  background: #fa6855;
}
.leaderboard ol li:nth-child(2) {
  background: #e0574f;
}
.leaderboard ol li:nth-child(2)::after {
  background: #e0574f;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .08);
}
.leaderboard ol li:nth-child(2) mark::before, .leaderboard ol li:nth-child(2) mark::after {
  border-top: 6px solid #ba4741;
  bottom: -7px;
}
.leaderboard ol li:nth-child(3) {
  background: #d7514d;
}
.leaderboard ol li:nth-child(3)::after {
  background: #d7514d;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .11);
}
.leaderboard ol li:nth-child(3) mark::before, .leaderboard ol li:nth-child(3) mark::after {
  border-top: 2px solid #b0433f;
  bottom: -3px;
}
.leaderboard ol li:nth-child(4) {
  background: #cd4b4b;
}
.leaderboard ol li:nth-child(4)::after {
  background: #cd4b4b;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .15);
}
.leaderboard ol li:nth-child(4) mark::before, .leaderboard ol li:nth-child(4) mark::after {
  top: -7px;
  bottom: auto;
  border-top: none;
  border-bottom: 6px solid #a63d3d;
}
.leaderboard ol li {
  background: #c24448;
  border-radius: 0 0 10px 10px;
}
.leaderboard ol li::after {
  background: #c24448;
  box-shadow: 0 -2.5px 0 rgba(0, 0, 0, .12);
  border-radius: 0 0 10px 10px;
}
.leaderboard ol li mark::before, .leaderboard ol li mark::after {
  top: -9px;
  bottom: auto;
  border-top: none;
  border-bottom: 8px solid #993639;
}
.leaderboard ol li:hover {
  z-index: 2;
  overflow: visible;
}
.leaderboard ol li:hover::after {
  opacity: 1;
  transform: scaleX(1.06) scaleY(1.03);
}
.leaderboard ol li:hover mark::before, .leaderboard ol li:hover mark::after {
  opacity: 1;
  transition: all 0.35s ease-in-out;
}




/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



#container {
  top: 3.5vmin;
  position: relative;
  background: #d0d0ff;
  height: 80vmin;
  width: 80vmin;
  margin: 0 auto;
  padding: 8vmin;
  border-radius: 2px;
  font-family: cursive;
}
::selection{
  background-color: transparent;
}
#grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  height: 100%;
  width: 100%;
   gap: 1%;
  margin: 1% 0;
  box-sizing: border-box;
  border: 1px solid #121212;
  padding: 1%;
}

.block {
  border-radius: 2px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  color: white;
  text-shadow:  -0.3vmin -0.3vmin 0.2vmin #000, 0.3vmin -0.3vmin 0.2vmin #000, -0.3vmin 0.3vmin 0.2vmin #000,
    0.3vmin 0.3vmin 0.2vmin #000, -0.3vmin 0 0.2vmin #000, 0.3vmin 0 0.2vmin #000, 0 -0.3vmin 0.3vmin #000,
    0 0.3vmin 0.2vmin #000, 0 -0.3vmin 0.2vmin #000, -0.3vmin 0 0.2vmin #000, 0 -0.3vmin 0.3vmin #000,
    0.3vmin 0 0.2vmin #000;
  text-align: center;
  font-size: 15vmin;

}

.b0, .b1, .b2, .b3, .b6 {
  background: #aa30dd;
    cursor: pointer;
}

.b4, .b5, .b7 {
  background: #ff55dd;
    cursor: pointer;
}

span{
  position: relative;
  font-size: 3.5vmin;
}
button {
  font-size: 4vmin;
}

#header > span {
  background: #a0a0dd;
  border-radius: 1vmin;
  top: 0;
}

#header {
  position: absolute;
 top: 0;
  margin: 2% 2.5%;
  left: 0;
  height: 6vmin;
  width: 90%;
  display: grid;
    grid-template-columns: repeat(3, 33%);
  grid-template-rows: repeat(1, 90%);
  gap: 3%;
}

#footer {
  position: absolute;
 bottom: 0;
  margin: 0.5% 2.5%;
  left: 0;
  height: 6vmin;
  width: 90%;
  display: grid;
    grid-template-columns: repeat(3, 33%);
  grid-template-rows: repeat(1, 90%);
  gap: 3%;
}

#footer > span {
  background: #a0a0dd;
  border-radius: 1vmin;
  bottom: 0;
}